import React from "react";
import styles from './stat_box.module.css';
import stats from "../../../../utils/event_types";

function Box({ object, countByAssignation }) {
    const count = countByAssignation[object.slug] !== undefined ? countByAssignation[object.slug] : 0;

    return (
        <div className={styles.box}>
            <div className={styles.boxDesc} style={{ backgroundColor: object.color }}>
                <div>{object.slug}</div>
            </div>
            <div className='d-flex align-items-center justify-content-center'>
                <div className={styles.boxNumber}>{count}</div>
                <div className={`${styles.boxText} pe-3`}>{object.name}</div>
            </div>
        </div>
    );
}

export default function StatBox({ events, eventFiltersComplete, countByAssignation }) {
    // Si events es undefined, asignar una lista de los primeros 50 números enteros desde 0
    events = events || Array.from({ length: 50 }, (_, i) => i);

    return (
        <div className={styles.container}>
            <div className={styles.box} style={{ backgroundColor: 'rgba(195,209,239,0.5)', color: '#6A86C8' }}>
                <div className={'d-flex align-items-center justify-content-center w-100'}>
                    <div className={styles.boxNumber} style={{ color: '#6A86C8' }}>3000</div>
                    <div className={styles.boxText} style={{ color: '#6A86C8' }}>Sin Roster</div>
                </div>
            </div>
            {stats.filter((stat, index) => !eventFiltersComplete || events.includes(index)).map((stat, index) => {
                return <Box key={index} object={stat} countByAssignation={countByAssignation} />
            })}
        </div>
    )
}

