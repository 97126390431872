import { PermissionType } from "./PermissionType";
/*
 0 -> Control total
 1 -> Escritura
 2 -> Lectura
 3 -> Sin acceso
*/

// TODO: Afectar rutas
class RoleManager {
    constructor() {
        this.roles = this.loadRolesFromLocalStorage();
    }

    loadRolesFromLocalStorage() {
        const roles = localStorage.getItem('role');
        console.log("FROM ROLE MANAGER", JSON.parse(roles));
        return roles ? JSON.parse(roles) : null;
    }

    getPermissionFromInteger(permission) {
        switch (permission) {
            case 0:
                return PermissionType.WRITE;
            case 1:
                return PermissionType.WRITE;
            case 2:
                return PermissionType.READ;
            case 3:
                return PermissionType.NOT_ALLOWED;
            default:
                return PermissionType.NOT_ALLOWED;
        }
    }

    getDashboardPermission() {
        if (!this.roles) {
            return PermissionType.NOT_ALLOWED;
        }

        const permissions = Object.values(this.roles).map(role => role.dashboard_permission);

        const validPermissions = permissions.filter(permission => permission !== null && permission !== undefined);

        if (validPermissions.length === 0) return PermissionType.NOT_ALLOWED;

        return this.getPermissionFromInteger(Math.min(...validPermissions));

    }

    getUsersPermission() {  
        if (!this.roles) {
            return PermissionType.NOT_ALLOWED;
        }

        const permissions = Object.values(this.roles).map(role => role.master_permission);

        const validPermissions = permissions.filter(permission => permission !== null && permission !== undefined);

        if (validPermissions.length === 0) return PermissionType.NOT_ALLOWED;

        return this.getPermissionFromInteger(Math.min(...validPermissions));
    }

    getRosterPermission() {
        if (!this.roles) {
            return PermissionType.NOT_ALLOWED;
        }

        const permissions = Object.values(this.roles).map(role => role.roster_permission);

        const validPermissions = permissions.filter(permission => permission !== null && permission !== undefined);

        if (validPermissions.length === 0) return PermissionType.NOT_ALLOWED;

        return this.getPermissionFromInteger(Math.min(...validPermissions));
    }

    getRosterApprovePermission() {
        if (!this.roles) {
            return PermissionType.NOT_ALLOWED;
        }

        const permissions = Object.values(this.roles).map(role => role.aproval_permission);

        const validPermissions = permissions.filter(permission => permission !== null && permission !== undefined);

        if (validPermissions.length === 0) return PermissionType.NOT_ALLOWED;

        return this.getPermissionFromInteger(Math.min(...validPermissions));
    }

    getRosterBalancesPermission() {
        if (!this.roles) {
            return PermissionType.NOT_ALLOWED;
        }

        const permissions = Object.values(this.roles).map(role => role.programation_permission);

        const validPermissions = permissions.filter(permission => permission !== null && permission !== undefined);

        if (validPermissions.length === 0) return PermissionType.NOT_ALLOWED;

        return this.getPermissionFromInteger(Math.min(...validPermissions));
    }

    getSettingsRosterDateLimitPermission() {
        if (!this.roles) {
            return PermissionType.NOT_ALLOWED;
        }

        const permissions = Object.values(this.roles).map(role => role.limit_date_permission);

        const validPermissions = permissions.filter(permission => permission !== null && permission !== undefined);

        if (validPermissions.length === 0) return PermissionType.NOT_ALLOWED;

        return this.getPermissionFromInteger(Math.min(...validPermissions));
    }

    getSettingsRolesPermission() {
        if (!this.roles) {
            return PermissionType.NOT_ALLOWED;
        }

        const permissions = Object.values(this.roles).map(role => role.roles_users_permission);

        const validPermissions = permissions.filter(permission => permission !== null && permission !== undefined);

        if (validPermissions.length === 0) return PermissionType.NOT_ALLOWED;

        return this.getPermissionFromInteger(Math.min(...validPermissions));
    }

    getSettingsUsersRolesPermission() {
        if (!this.roles) {
            return PermissionType.NOT_ALLOWED;
        }

        const permissions = Object.values(this.roles).map(role => role.users_permission);

        const validPermissions = permissions.filter(permission => permission !== null && permission !== undefined);

        if (validPermissions.length === 0) return PermissionType.NOT_ALLOWED;

        return this.getPermissionFromInteger(Math.min(...validPermissions));
    }

    getSettingsBoardingZonesPermission() {
        if (!this.roles) {
            return PermissionType.NOT_ALLOWED;
        }

        const permissions = Object.values(this.roles).map(role => role.boarding_area_permission);

        const validPermissions = permissions.filter(permission => permission !== null && permission !== undefined);

        if (validPermissions.length === 0) return PermissionType.NOT_ALLOWED;

        return this.getPermissionFromInteger(Math.min(...validPermissions));
    }

    getSettingsFrontsPermission() {
        if (!this.roles) {
            return PermissionType.NOT_ALLOWED;
        }

        const permissions = Object.values(this.roles).map(role => role.front_permission);

        const validPermissions = permissions.filter(permission => permission !== null && permission !== undefined);

        if (validPermissions.length === 0) return PermissionType.NOT_ALLOWED;

        return this.getPermissionFromInteger(Math.min(...validPermissions));
    }

    getSettingsGuardsPermission() {
        if (!this.roles) {
            return PermissionType.NOT_ALLOWED;
        }

        const permissions = Object.values(this.roles).map(role => role.guard_permission);

        const validPermissions = permissions.filter(permission => permission !== null && permission !== undefined);

        if (validPermissions.length === 0) return PermissionType.NOT_ALLOWED;

        return this.getPermissionFromInteger(Math.min(...validPermissions));
    }

    getSettingsEnterprisesPermission() {
        if (!this.roles) {
            return PermissionType.NOT_ALLOWED;
        }

        const permissions = Object.values(this.roles).map(role => role.enterprise_premission);

        const validPermissions = permissions.filter(permission => permission !== null && permission !== undefined);

        if (validPermissions.length === 0) return PermissionType.NOT_ALLOWED;

        return this.getPermissionFromInteger(Math.min(...validPermissions));
    }

    getSettingsNotificationsPermission() {
        if (!this.roles) {
            return PermissionType.NOT_ALLOWED;
        }

        const permissions = Object.values(this.roles).map(role => role.notifications_users_permission); 

        const validPermissions = permissions.filter(permission => permission !== null && permission !== undefined);

        if (validPermissions.length === 0) return PermissionType.NOT_ALLOWED;

        return this.getPermissionFromInteger(Math.min(...validPermissions));
    }

    getHighestRole(){
        let currentMin = 1000;
        let currentRole = null;
        for (let role of this.roles){
            let sum = role.dashboard_permission + role.users_permission + role.roster_permission;
            if (sum < currentMin){
                currentMin = sum;
                currentRole = role;
            }
        }
        return currentRole.name;
    }
    
    determineAccesibleRoute() {
        if (this.getDashboardPermission() !== PermissionType.NOT_ALLOWED) {
            return '/';
        }

        if (this.getRosterPermission() !== PermissionType.NOT_ALLOWED) {
            return '/roster';
        }

        if (this.getUsersPermission() !== PermissionType.NOT_ALLOWED) {
            return '/settings/users';
        }

        if (this.getSettingsUsersRolesPermission() !== PermissionType.NOT_ALLOWED) {
            return '/settings/users-and-permissions';
        }

        if (this.getSettingsRolesPermission() !== PermissionType.NOT_ALLOWED) {
            return '/settings/roles';
        }

        if (this.getSettingsRosterDateLimitPermission() !== PermissionType.NOT_ALLOWED) {
            return '/settings/projects';
        }

        if (this.getRosterBalancesPermission() !== PermissionType.NOT_ALLOWED) {
            return '/settings/balances';
        }

        if (this.getSettingsGuardsPermission() !== PermissionType.NOT_ALLOWED) {
            return '/settings/guards';
        }

        if (this.getSettingsFrontsPermission() !== PermissionType.NOT_ALLOWED) {
            return '/settings/fronts';
        }

        if (this.getSettingsBoardingZonesPermission() !== PermissionType.NOT_ALLOWED) {
            return '/settings/boarding-zones';
        }

        if (this.getSettingsEnterprisesPermission() !== PermissionType.NOT_ALLOWED) {
            return '/settings/enterprises';
        }

        if (this.getSettingsNotificationsPermission() !== PermissionType.NOT_ALLOWED) {
            return '/settings/notifications';
        }

        return '/login';
    }
}

export default RoleManager;
