import React, {useState} from "react";
import styles from './change_password.module.css';
import {Text} from "../../ajonjolib/inputs/ajonjolinput";
import {useNavigate} from "react-router-dom";
import {axiosUnAuthInstance} from "../../AxiosInstance";
import {toast, ToastTypes} from "../../ajonjolib/toasts/toast/toast";

export default function ChangePasswordMail() {
    const [email, setEmail] = useState("");
    const navigate = useNavigate();

    const submit = () => {
        axiosUnAuthInstance.post('password/reset/', {
            email: email,
        }).then((response) => {
            if (response.status !== 200) {
                toast('Ocurrió un error', ToastTypes.ERROR);
                return;
            }
            toast('Revisa el link que enviamos a tu correo.', ToastTypes.SUCCESS);
            console.log(response);
        }).catch(error => {
            console.log(error);
        });
    }

    return (
        <div className={styles.container}>
            <div className={styles.straconLogo}>
                <img src={'/branding/stracon_white.png'} alt={'stracon'} height={'43px'}/>
            </div>
            <div className={styles.innerContainer}>
                <div style={{flexBasis: '40%', overflowX: 'hidden'}}>
                    <img alt={'tracks'}  src={'/branding/tracks_dark_long.png'} height={'90px'}/>
                </div>

                <div>Restablecer la contraseña</div>

                <div style={{flexBasis: '40%', overflowX: 'hidden'}}>
                    <img alt={'tracks'}  src={'/branding/tracks_dark_long.png'} height={'90px'}/>
                </div>
            </div>

            <div className={'mt-4 d-flex justify-content-center align-items-center'}>
                <div style={{
                    flexBasis: '25%', textAlign: 'left',
                    color: '#ffffff',
                    fontSize: '16px',
                }}>
                    <div>Indícanos tu dirección de correo electrónico o nombre de usuario y te enviaremos un enlace para que puedas volver a acceder a tu cuenta.</div>
                    <div className={'mt-4'}/>
                    <div style={{fontSize: '16px', color: '#FFF', textAlign: 'left'}}>Dirección de correo electrónico</div>
                    <Text className={'mt-2'} style={{fontSize: '16px', height: '40px'}} value={email} onChange={(val) => {
                        setEmail(val);
                    }}/>
                    <div className={'pt-4'}>
                        <div style={{
                            backgroundColor: '#F32735', padding: '10px 25px', borderRadius: '43px', cursor: 'pointer',
                            fontSize: '16px', textAlign: 'center',
                            fontWeight: '500'
                        }} onClick={() => submit()}>Enviar Enlace</div>
                    </div>
                </div>
            </div>
        </div>
    )
}