import React, {useEffect, useState} from "react";
import styles from './page_header.module.css';
import header_styles from "../../../../components/page_title/page_title.module.css";
import {axiosInstance} from "../../../../AxiosInstance";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faFilter} from "@fortawesome/free-solid-svg-icons";
import {Select, TextSearcher} from "../../../../ajonjolib/inputs/ajonjolinput";

function HeaderSelect({query, setQuery, setShowModal, setShowFilterModal}) {
    const [allProjects, setAllProjects] = useState([]);
    const [allAreas, setAllAreas] = useState([]);
    const [groupProjects, setGroupProjects] = useState([]);
    const [groupProjectsIds, setGroupProjectsIds] = useState(null);
    const [projects, setProjects] = useState([]);
    const [areas, setAreas] = useState([]);

    useEffect(() => {
        axiosInstance.get('project_group/').then((response) => {
            setGroupProjects(response.data);
        });
        axiosInstance.get('/project').then((response) => {
            setAllProjects(response.data.results);
            setProjects(response.data.results);
        });
        axiosInstance.get('/area').then((response) => {
            setAllAreas(response.data);
        });
    }, []);

    useEffect(() => {
        const selectedProjects = query['project'];
        if (selectedProjects) {
            const filteredAreas = allAreas.filter((area) => selectedProjects.includes(area.project))
            setAreas(filteredAreas)
        }
    }, [query['project']])

    const handleProjectGroupChange = (selectedGroupProjects) => {
        const filteredProjects = allProjects.filter(project => selectedGroupProjects.includes(project.group));
        setProjects(filteredProjects);
        setQuery({...query, 'project': null});
        setGroupProjectsIds(selectedGroupProjects);
    }

    const handleDateChange = (field, value) => {
        setQuery({...query, [field]: value});
    };

    return (
        <div className={'d-flex align-items-center w-100 py-2'}>
            { /*
            <div className={styles.filters} onClick={() => setShowFilterModal(true)}>
                <FontAwesomeIcon icon={faFilter} color={'#FFF'} className={'pe-2'}/>
                <div>Filtros</div>
            </div>
            */
            }
            <div className={styles.select}>
                <Select 
                    multi={true}
                    hideSelectedAllItems={true}
                    style={{minWidth: '200px',}}
                    value={groupProjectsIds? groupProjectsIds : ""}
                    options={groupProjects?.map((g) => {return {value: g.id, name: g.name}})}
                    onChange={(val) => handleProjectGroupChange(val)}
                />
            </div>
            <div className={styles.select}>
                <Select 
                    multi={true}
                    hideSelectedAllItems={true}
                    key={groupProjectsIds || 'default-key'}
                    style={{minWidth: '200px',}}
                    value={query['project']}
                    options={projects?.map((g) => {return {value: g.id, name: g.name}})} 
                    onChange={(val) => setQuery({...query, 'project': val})}/>
            </div>
            <div className={styles.select}>
                <Select 
                    multi={true}
                    hideSelectedAllItems={true}
                    key={areas || 'default-key'}
                    style={{minWidth: '200px',}}
                    value={query['area']}
                    options={areas?.map((area) => {return {value: area.id, name: area.name}})} 
                    onChange={(val) => setQuery({...query, 'area': val})}/>
            </div>
            <div className={`${styles.date} ${query.hasOwnProperty('start_date') ? styles.dateSelected : ''}`}>
                <div>
                    {/* Los inputs de fecha siguen utilizando el formato yyyy-mm-dd internamente */}
                    <input 
                        type={'date'} 
                        value={query['start_date']} 
                        onChange={(event) => handleDateChange('start_date', event.target.value)}
                    />
                    <input 
                        type={'date'} 
                        min={query['start_date']}
                        value={query['end_date']} 
                        onChange={(event) => handleDateChange('end_date', event.target.value)}
                    />
                </div>
            </div>
        </div>
    )
}

export default function PageHeader({query, setQuery, setShowFilterModal}) {
    return (
        <div className={header_styles.container}>
            <div>
                <div className={'d-flex align-items-end'}>
                    <div className={header_styles.title}>Dashboard</div>
                </div>
                <HeaderSelect setShowFilterModal={setShowFilterModal} setQuery={setQuery} query={query}/>
            </div>
            <div className={header_styles.divider}></div>
        </div>
    )
}