import React, {useState} from "react";
import styles from './change_password.module.css';
import {TextPassword} from "../../ajonjolib/inputs/ajonjolinput";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLock} from "@fortawesome/free-solid-svg-icons";
import {useNavigate, useParams } from "react-router-dom";
import {axiosUnAuthInstance} from "../../AxiosInstance";

export default function ChangePasswordPassword() {
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");
    const navigate = useNavigate();
    const { uidb64, token } = useParams();

    const submit = () => {
        if(password !== password2) {
            alert('Las contraseñas no coinciden');
            return;
        } else {
            axiosUnAuthInstance.post(`password/reset/confirm/${uidb64}/${token}/`, {
                password: password
            }).then((response) => {
                if (response.status !== 200) {
                    alert('Ocurrió un error');
                    return;
                }
                console.log(response);
            }).catch(error => {
                console.log(error);
                return;
            });
        }

        navigate('/password_reset/success');
    }

    return (
        <div className={styles.container}>
            <div className={styles.straconLogo}>
                <img src={'/branding/stracon_white.png'} alt={'stracon'} height={'43px'}/>
            </div>
            <div className={styles.innerContainer}>
                <div style={{flexBasis: '40%', overflowX: 'hidden'}}>
                    <img alt={'tracks'}  src={'/branding/tracks_dark_long.png'} height={'90px'}/>
                </div>

                <div>Restablecer la contraseña</div>

                <div style={{flexBasis: '40%', overflowX: 'hidden'}}>
                    <img alt={'tracks'}  src={'/branding/tracks_dark_long.png'} height={'90px'}/>
                </div>
            </div>

            <div className={'mt-4 d-flex justify-content-center align-items-center'}>
                <div style={{
                    flexBasis: '25%', textAlign: 'left',
                    color: '#ffffff',
                    fontSize: '16px',
                }}>
                    <div>Ingresa tu nueva contraseña</div>
                    <div className={'mt-4'}/>
                    <div style={{fontSize: '16px', color: '#FFF', textAlign: 'left'}} className={'d-flex align-items-center'}>
                        <FontAwesomeIcon icon={faLock} style={{color: '#FFF', marginRight: '10px'}}/>
                        <div>Nueva Contraseña</div>
                    </div>
                    <TextPassword className={'mt-2'} style={{fontSize: '16px', height: '40px'}} value={password} onChange={(val) => {
                        setPassword(val);
                    }}/>
                    <div className={'mt-4'}/>
                    <div style={{fontSize: '16px', color: '#FFF', textAlign: 'left'}} className={'d-flex align-items-center'}>
                        <FontAwesomeIcon icon={faLock} style={{color: '#FFF', marginRight: '10px'}}/>
                        <div>Confirmar Contraseña</div>
                    </div>
                    <TextPassword className={'mt-2'} style={{fontSize: '16px', height: '40px'}} value={password2} onChange={(val) => {
                        setPassword2(val);
                    }}/>
                    <div className={'pt-4'}>
                        <div style={{
                            backgroundColor: '#F32735', padding: '10px 25px', borderRadius: '43px', cursor: 'pointer',
                            fontSize: '16px', textAlign: 'center',
                            fontWeight: '500'
                        }} onClick={() => submit()}>Restablecer contraseña</div>
                    </div>
                </div>
            </div>
        </div>
    )
}